<template>
  <el-dialog
    title="增加推广员"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="用户" prop="userId">
        <span>{{ modalData.realName }}</span>
      </el-form-item>

      <el-form-item label="邀请者" prop="inviter">
        <eb-filter-user-list v-model="modalData.inviter"></eb-filter-user-list>
      </el-form-item>

    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>
      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import {bindInviter} from "@/api/user";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType"],
  data() {
    return {
      formName: "form",
      ruleValidate: {
        inviter: [
          {
            required: true,
            message: "推荐者不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },

  mounted() {
  },

  methods: {
    submit() {
      let obj = {
        id: this.modalData.id,
        inviteUid: this.modalData.inviter
      };
      this.validateForm().then((res) => {
        bindInviter(obj).then(res=>{
          this.$emit("submit");
        })
      });
    },
  },
};
</script>